import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="w-full py-4">
      <div className="container mx-auto text-center">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Ethiopian Black Market Exchange |{" "}
          <Link to="/terms" className="text-blue-500 hover:underline">
            Terms of Service
          </Link>{" "}
          |{" "}
          <Link to="/privacy" className="text-blue-500 hover:underline">
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link to="/about" className="text-blue-500 hover:underline">
            About Us
          </Link>{" "}
          |{" "}
          <Link to="/contact" className="text-blue-500 hover:underline">
            Contact
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
