import { Link } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHome,
  faBlog,
  faCoins,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="w-full py-4 shadow mb-4">
      <div className="container mx-auto px-6">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-xl font-extrabold flex items-center">
            Ethiopian Black Market Exchange
          </Link>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-3xl focus:outline-none"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <nav
            className={`${
              isMenuOpen ? "block" : "hidden"
            } md:flex md:items-center md:space-x-6`}
          >
            <Link
              to="/"
              className="block py-2 md:py-0 px-4 hover:text-indigo-300 flex items-center"
              onClick={() => setIsMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faHome} className="mr-2" /> Home
            </Link>
            <Link
              to="/blog"
              className="block py-2 md:py-0 px-4 hover:text-indigo-300 flex items-center"
              onClick={() => setIsMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faBlog} className="mr-2" /> Blog
            </Link>
            <Link
              to="cryptocurrency"
              className="block py-2 md:py-0 px-4 hover:text-indigo-300 flex items-center"
              onClick={() => setIsMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faCoins} className="mr-2" /> Cryptocurrency
              Exchanges Rate
            </Link>
            <Link
              to="exchangeRate"
              className="block py-2 md:py-0 px-4 hover:text-indigo-300 flex items-center"
              onClick={() => setIsMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" /> Bank
              Exchange Rate
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
