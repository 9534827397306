import React from "react";

const Privacy = () => {
  return (
    <div className="container mx-auto p-6 text-gray-800">
      <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-6">
        This Privacy Policy describes how we collect, use, and protect your
        personal information when you use our services. Your privacy is of
        utmost importance to us, and we are committed to ensuring your data is
        handled responsibly.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Information Collection</h2>
      <p className="mb-6">
        We collect personal information that you provide to us directly, such as
        your name, email address, and other details you choose to share.
        Additionally, we may collect information automatically through cookies
        and similar technologies to enhance your experience.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        How We Use Your Information
      </h2>
      <p className="mb-6">
        The information we collect is used to provide and improve our services,
        respond to inquiries, personalize your experience, and ensure the safety
        and security of our users. We may also use your information to send you
        important updates or promotional offers (you can opt-out at any time).
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        Cookies and Tracking Technologies
      </h2>
      <p className="mb-6">
        We use cookies to improve your browsing experience by remembering your
        preferences and understanding how you use our website. Cookies are small
        data files that help us enhance the performance of our site. You can
        control your cookie preferences through your browser settings.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Third-Party Services</h2>
      <p className="mb-6">
        We may use third-party services for analytics and advertisements. These
        services may collect information to provide targeted ads and analyze
        traffic. We only work with trusted third parties who are committed to
        safeguarding your data.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
      <p className="mb-6">
        We take reasonable measures to protect your personal information from
        unauthorized access, alteration, or disclosure. However, please note
        that no method of transmission over the internet is 100% secure, and we
        cannot guarantee absolute security.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
      <p className="mb-6">
        You have the right to access, modify, or delete the personal information
        we hold about you. If you wish to exercise these rights, please contact
        us. We will respond to your request within a reasonable timeframe.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Changes to This Policy</h2>
      <p className="mb-6">
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal obligations. Any updates will be posted on
        this page, and the effective date will be revised accordingly. We
        encourage you to review this policy periodically.
      </p>
    </div>
  );
};

export default Privacy;
