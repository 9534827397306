import React from "react";

const Terms = () => {
  return (
    <div className="container mx-auto p-6 text-gray-800">
      <h1 className="text-4xl font-bold mb-6">Terms of Service</h1>
      <p className="mb-6">
        By using our website and services, you agree to be bound by the
        following terms and conditions. Please read them carefully.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Acceptance of Terms</h2>
      <p className="mb-6">
        By accessing or using our services, you acknowledge that you have read,
        understood, and agree to be bound by these terms. If you do not agree
        with any part of these terms, you must not use our services.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Use of Services</h2>
      <p className="mb-6">
        Our services are intended for personal and non-commercial use. You agree
        to use our services only for lawful purposes and in compliance with all
        applicable laws. Any misuse of our services, including but not limited
        to attempts to disrupt our platform or access unauthorized areas, may
        result in termination of your access.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Account Responsibilities</h2>
      <p className="mb-6">
        To access certain features, you may need to create an account. You are
        responsible for keeping your account information secure and ensuring the
        accuracy of the information provided. You must notify us immediately if
        you suspect any unauthorized use of your account.
      </p>
      <h2 className="text-2xl font-semibold mb-4">
        Intellectual Property Rights
      </h2>
      <p className="mb-6">
        All content provided on this website, including text, graphics, logos,
        and images, is the property of Ethiopian Black Market Exchange or its
        licensors. You may not reproduce, distribute, or create derivative works
        from our content without prior written consent.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Disclaimer of Warranties</h2>
      <p className="mb-6">
        Our services are provided "as is" without warranties of any kind. We
        strive to ensure the accuracy of the information provided but do not
        guarantee the reliability, completeness, or timeliness of the content.
        Your use of our services is at your own risk.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
      <p className="mb-6">
        Ethiopian Black Market Exchange shall not be liable for any direct,
        indirect, incidental, or consequential damages arising from your use of
        our services. This includes, but is not limited to, loss of data,
        profits, or interruptions in service. We are not responsible for any
        third-party content or links available on our site.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Termination</h2>
      <p className="mb-6">
        We reserve the right to terminate or suspend your access to our services
        at our sole discretion if we believe you have violated these terms or
        engaged in conduct harmful to other users or our platform.
      </p>
    </div>
  );
};

export default Terms;
