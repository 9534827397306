import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./views/Home";
import About from "./views/About";
import Contact from "./views/Contact";
import ExchnageRate from "./views/ExchangeRate";
import MainLayout from "./layout/MainLayout";
import Cryptocurrency from "./views/CryptocurrencyRate";
import Blog from "./views/Blog";
import Privacy from "./views/Privacy";
import Terms from "./views/Terms";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/exchangeRate" element={<ExchnageRate />} />
          <Route path="/cryptocurrency" element={<Cryptocurrency />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
