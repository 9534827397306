// src/pages/Blog.js
import React from "react";
import { Link } from "react-router-dom";

const Blog = () => {
  const articles = [
    {
      id: 1,
      title: "Understanding Currency Exchange Rates",
      content:
        "Currency exchange rates are crucial for international trade and tourism. They determine the value of one currency in terms of another. Factors like inflation, interest rates, and economic stability can affect exchange rates. In Ethiopia, exchange rates are influenced by both the official rate set by the National Bank of Ethiopia and the informal rates found in black markets. Understanding these dynamics can help individuals and businesses make informed financial decisions.",
    },
    {
      id: 2,
      title: "Impact of Currency Fluctuations on Ethiopian Businesses",
      content:
        "Currency fluctuations can have significant impacts on businesses in Ethiopia, particularly those engaged in importing goods. When the value of the Ethiopian Birr decreases, the cost of imports rises, which in turn raises prices for consumers. Exporters, however, may benefit as their goods become cheaper for foreign buyers. Understanding how these fluctuations affect pricing, supply chains, and overall business operations is essential for managing financial risk in Ethiopia.",
    },
    {
      id: 3,
      title: "Tips for Getting the Best Exchange Rate",
      content:
        "When exchanging currency in Ethiopia, it is important to compare rates from different providers. Banks and official exchange bureaus tend to offer more stable rates compared to black market dealers, although the latter might offer better rates in some cases. Planning your currency exchange in advance, avoiding peak times, and staying informed about current exchange rates can help you get the best value. Always be cautious when dealing with unofficial channels, as they carry higher risks.",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <h2 className="text-4xl font-bold mb-8 text-gray-800">Blog</h2>
      <div className="container mx-auto">
        {articles.map((article) => (
          <div
            key={article.id}
            className="bg-white text-black p-6 rounded-lg mb-8 shadow-md"
          >
            <h3 className="text-2xl font-bold mb-2 text-gray-900">
              {article.title}
            </h3>
            <p className="mb-4">{article.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
