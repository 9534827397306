import React from "react";

const About = () => {
  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">
        About Us
      </h2>
      <p className="text-lg text-gray-700 leading-relaxed mb-6">
        Ethiopian Black Market is dedicated to providing accurate and up-to-date
        information on currency exchange rates in Ethiopia. Our mission is to
        offer a transparent and reliable platform for individuals and businesses
        looking to stay informed about the fluctuating exchange rates in the
        black market.
      </p>
      <p className="text-lg text-gray-700 leading-relaxed mb-6">
        We are committed to ensuring that our users have access to the most
        current data, helping them make informed decisions when it comes to
        currency exchanges. Our platform is user-friendly and accessible,
        designed to cater to both local and international audiences.
      </p>
      <p className="text-lg text-gray-700 leading-relaxed">
        Our team is comprised of professionals with extensive experience in
        finance and technology. We work tirelessly to gather data from trusted
        sources and present it in a clear and concise manner. Ethiopian Black
        Market is your go-to resource for all your currency exchange needs.
      </p>
      <div className="mt-8">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">
          Our Core Values
        </h3>
        <ul className="list-disc list-inside text-lg text-gray-700">
          <li>Transparency</li>
          <li>Accuracy</li>
          <li>Reliability</li>
          <li>Integrity</li>
        </ul>
      </div>
      <div className="mt-8">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">Contact Us</h3>
        <p className="text-lg text-gray-700 leading-relaxed">
          If you have any questions or need further information, feel free to
          reach out to us at{" "}
          <a
            href="mailto:info@ethiopianblackmarket.com"
            className="text-blue-600 underline"
          >
            info@ethiopianblackmarket.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default About;
