import React from "react";
import Header from "../views/Header";
import Footer from "../views/Footer";
import Adsense from "../views/Adsense";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <div className="container mx-auto items-center text-gray-700">
      <Header />
      <main>
        <Outlet /> {/* This will render the current route's component */}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
