import LineChart from "../components/LineChart";
import React from "react";

function Home() {
  return (
    <main className="flex-grow flex flex-col container mx-auto items-center justify-center text-center px-6 py-2 md:py-4">
      <h2 className="text-3xl md:text-4xl font-extrabold mb-4 text-gray-800">
        Welcome to Ethiopian Black Market Ex
      </h2>
      <p className="text-lg md:text-xg mb-2 text-gray-800">
        We provide real-time and historical data on the Ethiopian black market
        USD/ETB exchange rate.
        <br />
        Trusted and 100% reliable source
      </p>
      <div className="w-full flex justify-center mb-24 text-gray-800">
        <LineChart />
      </div>
      {/* <p className="text-lg md:text-xg mb-2 font-semibold text-gray-700">
        Ethiopian black market rate in different countries around the world
      </p>
      <table class="min-w-full table-auto text-gray-800 bg-white rounded-lg shadow-md mt-4 mb-12">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border-b">Country</th>
            <th className="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2">1 USD in United States of America</td>
            <td className="px-4 py-2">139.90 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 GBP in United Kingdom (England)</td>
            <td className="px-4 py-2">168.35 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 EUR in Italy</td>
            <td className="px-4 py-2">143.75 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 EUR in Germany</td>
            <td className="px-4 py-2">144.10 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 EUR in Sweden</td>
            <td className="px-4 py-2">143.70 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 SEK in Saudi Arabia</td>
            <td className="px-4 py-2">12.68 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 AED in UAE</td>
            <td className="px-4 py-2">33.83 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 ILS in China</td>
            <td className="px-4 py-2">17.99 ETB</td>
          </tr>
        </tbody>
      </table>
      <p className="text-lg md:text-xg mb-2 font-semibold text-gray-700">
        Compare today's Euro to Ethiopian Birr (EUR to ETB) exchange rates with
        remittance service providers to see the difference in fees and exchange
        rates.
      </p>
      <table class="min-w-full table-auto text-gray-800 bg-white rounded-lg shadow-md mt-4 mb-12">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border-b">1 EUR</th>
            <th className="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2">Revolut (no fee)</td>
            <td className="px-4 py-2">127.5948 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">Dahabshiil (no fee)</td>
            <td className="px-4 py-2">147.8886 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">Western Union</td>
            <td className="px-4 py-2">127.3601 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">Talke Remit</td>
            <td className="px-4 py-2">147.8886 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">Cambridge Currencies</td>
            <td className="px-4 py-2">128.9672 ETB</td>
          </tr>
        </tbody>
      </table>
      <p className="text-lg md:text-xg mb-2 font-semibold text-gray-700">
        Compare today's US Dollar to Ethiopian Birr (USD to ETB) exchange rates
        with remittance service providers to see the difference in fees and
        exchange rates.
      </p>
      <table className="min-w-full table-auto text-gray-800 bg-white rounded-lg shadow-md mt-4 mb-12">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border-b">1 USD</th>
            <th className="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2">Cash Go (no fee)</td>
            <td className="px-4 py-2">121.1201 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">Western Union</td>
            <td className="px-4 py-2">117.4480 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">Ria money transfer</td>
            <td className="px-4 py-2">106.5200 ETB</td>
          </tr>
          <tr>
            <td className="px-4 py-2">Cambridge Currencies</td>
            <td className="px-4 py-2">119.7818 ETB</td>
          </tr>
        </tbody>
      </table>
      <p className="text-lg md:text-xg mb-2 font-semibold text-gray-700">
        Eritrean Nakfa exchange rate from difference currencies
      </p>
      <table className="min-w-full table-auto text-gray-800 bg-white rounded-lg shadow-md mt-4">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border-b">1 Nakfa</th>
            <th className="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2">1 USD</td>
            <td className="px-4 py-2">15.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 GBP</td>
            <td className="px-4 py-2">20.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 EUR</td>
            <td className="px-4 py-2">17.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 SAR</td>
            <td className="px-4 py-2">4.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 SEK</td>
            <td className="px-4 py-2">1.50 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 AED</td>
            <td className="px-4 py-2">4.00 ERN</td>
          </tr>
          <tr>
            <td className="px-4 py-2">1 ILS</td>
            <td className="px-4 py-2">2.00 ERN</td>
          </tr>
        </tbody>
      </table> */}
    </main>
  );
}

export default Home;
